import { graphql } from 'gatsby'
import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Hero from '../components/EquipmentTabs/Hero'
import Footer from '../components/Footer'
import Getquote from '../components/Getquote'
import Layout from '../components/Layout'
import Post from '../components/Post'
import SEO from '../components/seo'
import fb from '../images/fb-icon.svg'
import linkedin from '../images/linkedin-icon.svg'
import plus from '../images/plus-icon.svg'
import youtubeorange from '../images/youtube-icon-orange.svg'


const BlogTemplate = ({ data }) => {
  console.log(data)
  const { headerImage, blogPosts } = data
  const { name } = blogPosts
  
  return (
    <Layout>
      <SEO title={name + ' | RoadLinx Shipping Company'} description={''} />
      <section className="free-resources">
        <Hero
          type="primary"
          image={headerImage.childImageSharp.fluid}
          title={name}
        />
        <Container className="free-resources__container" fluid={true}>
          <Row>
            <Col xl={7}>
              {blogPosts.posts.nodes.map(post => (
                <Post
                  title={post.title}
                  featuredImage={
                    post.featuredImage?.node.localFile.childImageSharp.fluid
                  }
                  link={post.uri}
                  excerpt={post.excerpt}
                  date={post.date}
                  author={post.author.node.name}
                />
              ))}
            </Col>
            <Col xl={4}>
              <div className="blog-post__icons-wrapper">
                <div className="blog-post__icon">
                  <a href="https://www.facebook.com/RoadLINX/" className="link">
                    <Image src={fb} />
                  </a>
                </div>
                <div className="blog-post__icon">
                  <a
                    href="https://www.linkedin.com/company/roadlinx/about/"
                    className="link"
                  >
                    <Image src={linkedin} />
                  </a>
                </div>
                <div className="blog-post__icon">
                  <a
                    href="https://www.youtube.com/channel/UCw-RZcawMBC2LpjkdMnV7FA/featured"
                    className="link"
                  >
                    <Image src={youtubeorange} />
                  </a>
                </div>
                <div className="blog-post__icon">
                  <a
                    href="https://plus.google.com/u/0/108831081307274188203"
                    className="link"
                  >
                    <Image src={plus} />
                  </a>
                </div>
              </div>
              <Getquote
                title="Get a quote today!"
                buttonLabel="Get a free estimate"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogTagByID($id: String!) {
    headerImage: file(relativePath: { eq: "blogheader.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blogPosts: wpTag(id: { eq: $id }) {
      id
      name
      slug
      uri
      posts {
        nodes {
          id
          title
          excerpt
          authorId
          date(formatString: "D MMM YYYY")
          slug
          uri
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
          author {
            node {
              name
            }
          }
        }
      }
    }
  }
`

export default BlogTemplate
